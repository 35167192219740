<template>
    <div>
         <Header />
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="exit-inform">
                        <button class="arraw-exit" @click="exit()">
                        <img src="../../assets/img/arraw-left.svg" alt="" srcset="" />
                        </button>
                        <span>Назад</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="main-container">
                        <p class="header-text">Редактирование личных данных банка</p>
                        <div class="input-box">
                            <p>Название банка</p>
                            <input type="text" required>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from "../../components/Header";
export default {
      components: { Header },
}
</script>
<style lang="scss">
.main-container {
    padding:58px 157px;
    background: #FFFFFF;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.04);
    border-radius: 9px;
    .header-text{
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height */


        /* 1E2321 */

        color: #1E2321;

    }
    .input-box {
        p {
            font-family: Gilroy;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #9B9B9B;
        }
        input {
            background: #FFFFFF;
            border: 1px solid #000000;
            box-sizing: border-box;
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
            border-radius: 9px;
        }
    }
}
</style>