<template>
  <header>
    <div class="container">
      <div class="header__row">
        <div class="header__left">
          <div class="header__logo">
            <a @click="$router.push('/')" v-if="GET_USER_DATA.admin == false">
              <img src="../assets/img/header-logo.png" />
            </a>
						<a @click="$router.push('/admin/panel')" v-if="GET_USER_DATA.admin == true && GET_USER_DATA.role.name == 'MAIN'">
              <img src="../assets/img/header-logo.png" />
            </a>
						<a @click="$router.push('/kcmr')" v-if="GET_USER_DATA.admin == true && GET_USER_DATA.role.name !== 'MAIN'">
              <img src="../assets/img/header-logo.png" />
            </a>
          </div>
          <div class="header__search">
            <!-- <form class="search__form">
              <input type="text" required name="" placeholder="Поиск" value="" />
              <button type="submit">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.53 15.59C12.8986 16.9486 10.8062 17.626 8.68801 17.4813C6.56986 17.3365 4.58906 16.3808 3.15763 14.8128C1.7262 13.2449 0.954348 11.1854 1.00263 9.06287C1.05092 6.94033 1.91562 4.91811 3.41687 3.41686C4.91812 1.91561 6.94034 1.05091 9.06287 1.00262C11.1854 0.95434 13.2449 1.72619 14.8128 3.15762C16.3808 4.58905 17.3365 6.56985 17.4813 8.688C17.6261 10.8061 16.9487 12.8986 15.59 14.53L21.28 20.22C21.3537 20.2887 21.4128 20.3715 21.4538 20.4635C21.4948 20.5555 21.5168 20.6548 21.5186 20.7555C21.5204 20.8562 21.5018 20.9562 21.4641 21.0496C21.4264 21.143 21.3703 21.2278 21.299 21.299C21.2278 21.3703 21.143 21.4264 21.0496 21.4641C20.9562 21.5018 20.8562 21.5204 20.7555 21.5186C20.6548 21.5168 20.5555 21.4948 20.4635 21.4538C20.3715 21.4128 20.2887 21.3537 20.22 21.28L14.53 15.59V15.59ZM2.5 9.25C2.50023 8.15331 2.76767 7.07319 3.27919 6.10309C3.7907 5.133 4.53088 4.30215 5.43569 3.68243C6.34049 3.06271 7.38267 2.67278 8.47205 2.54638C9.56143 2.41999 10.6652 2.56093 11.6879 2.95702C12.7105 3.3531 13.6213 3.99241 14.3413 4.81961C15.0614 5.64681 15.569 6.637 15.8204 7.7045C16.0717 8.772 16.0591 9.88467 15.7838 10.9462C15.5084 12.0078 14.9785 12.9863 14.24 13.797C14.1386 13.8342 14.0464 13.893 13.9699 13.9693C13.8934 14.0456 13.8344 14.1376 13.797 14.239C12.83 15.1204 11.6276 15.7018 10.3362 15.9123C9.04481 16.1229 7.72006 15.9535 6.52313 15.425C5.3262 14.8964 4.30871 14.0313 3.59445 12.935C2.88018 11.8387 2.49995 10.5584 2.5 9.25V9.25Z"
                    fill="#135334"
                  />
                </svg>
              </button>
            </form> -->
          </div>
        </div>
        <div class="header__right">
          <!-- <div class="header__leng">
            <ul>
              <li @click="$i18n.locale = 'kz'" class="header__lang--item" :class="[$i18n.locale == 'kz' ? 'header__lang--active' : '']"><a href="#">kz</a></li>
              <li @click="$i18n.locale = 'ru'" class="header__lang--item" :class="[$i18n.locale == 'ru' ? 'header__lang--active' : '']"><a href="#">ru</a></li>

            </ul>
          </div> -->

          <div class="header__notification">
            <v-menu
              v-model="bellView"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              content-class="header__notification__toggle"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="filter__book__hotel__block" v-bind="attrs" v-on="on">
                  <a>
                    <div class="notification--icon pointer">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 1C8.31802 1 5.00001 3.565 5.00001 7V11.539C5.00001 12.1807 4.81003 12.8081 4.45401 13.342L2.25401 16.641C2.10179 16.8697 2.01451 17.1355 2.00147 17.41C1.98843 17.6844 2.05012 17.9572 2.17996 18.1994C2.30981 18.4415 2.50294 18.6439 2.73877 18.7849C2.97459 18.9259 3.24426 19.0002 3.51901 19H8.50001C8.50001 19.4596 8.59054 19.9148 8.76644 20.3394C8.94233 20.764 9.20014 21.1499 9.52514 21.4749C9.85015 21.7999 10.236 22.0577 10.6606 22.2336C11.0853 22.4095 11.5404 22.5 12 22.5C12.4596 22.5 12.9148 22.4095 13.3394 22.2336C13.764 22.0577 14.1499 21.7999 14.4749 21.4749C14.7999 21.1499 15.0577 20.764 15.2336 20.3394C15.4095 19.9148 15.5 19.4596 15.5 19H20.482C20.7568 19 21.0264 18.9254 21.262 18.7842C21.4977 18.643 21.6907 18.4405 21.8203 18.1983C21.95 17.956 22.0114 17.6831 21.9982 17.4087C21.9849 17.1343 21.8974 16.8686 21.745 16.64L19.545 13.342C19.1894 12.808 18.9997 12.1806 19 11.539V7C19 3.565 15.681 1 12 1ZM6.50001 7C6.50001 4.636 8.88302 2.5 12 2.5C15.117 2.5 17.5 4.636 17.5 7V11.539C17.5 12.477 17.778 13.393 18.298 14.174L20.497 17.473C20.499 17.4759 20.5001 17.4794 20.5 17.483L20.499 17.489L20.495 17.495L20.489 17.499L20.482 17.5H3.51801L3.51101 17.499L3.50501 17.495L3.50101 17.489L3.50001 17.482L3.50301 17.472L5.70301 14.174C6.22296 13.3936 6.50027 12.4767 6.50001 11.539V7ZM14 19H10C10 19.5304 10.2107 20.0391 10.5858 20.4142C10.9609 20.7893 11.4696 21 12 21C12.5304 21 13.0392 20.7893 13.4142 20.4142C13.7893 20.0391 14 19.5304 14 19Z"
                          fill="#135334"
                        />
                      </svg>
                    </div>

                    <div class="notification--number">
                      {{ count }}
                    </div>
                  </a>
                </div>
              </template>
              <div class="pa-4 header__notification__area">
                <div
                  v-for="(item, index) in notifications"
                  class="header__notification__area__list"
                  :key="index"
                >
                  <div class="item__row item__ac">
                    <i class="mdi mdi-information-outline mr-2"></i>
                    <div class="item__column">
                      <p class="header__notification__area__title">
                        {{ JSON.parse(item.data).title }}
                      </p>
                      <p class="header__notification__area__text">
                        {{ JSON.parse(item.data).text }}
                      </p>

                      <a
                        class="header__notification__area__link"
                        v-if="GET_USER_DATA.admin == true"
                        @click="goTo(JSON.parse(item.data).link_admin, index)"
                        >Перейти</a
                      >
                      <a
                        class="header__notification__area__link"
                        v-if="GET_USER_DATA.admin == false"
                        @click="goTo(JSON.parse(item.data).link_user, index)"
                        >Перейти</a
                      >

                      <p class="header__notification__area__date">
                        {{ JSON.parse(item.data).date }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </v-menu>
          </div>

          <div class="header__login header__login--active">
            <div class="header__login--block">
              <div class="header__login--reletive">
                <div class="header__login--icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.5 1.75C7.5 0.784 8.284 0 9.25 0H14.75C15.716 0 16.5 0.784 16.5 1.75V4H21.25C22.216 4 23 4.784 23 5.75V20.25C23 20.7141 22.8156 21.1592 22.4874 21.4874C22.1592 21.8156 21.7141 22 21.25 22H2.75C2.28587 22 1.84075 21.8156 1.51256 21.4874C1.18437 21.1592 1 20.7141 1 20.25V5.75C1 4.784 1.784 4 2.75 4H7.5V1.75ZM2.5 11.99V20.25C2.5 20.388 2.612 20.5 2.75 20.5H21.25C21.3163 20.5 21.3799 20.4737 21.4268 20.4268C21.4737 20.3799 21.5 20.3163 21.5 20.25V11.99C20.7328 12.6433 19.7577 13.0014 18.75 13H5.25C4.24234 13.0014 3.26723 12.6433 2.5 11.99V11.99ZM21.5 8.75C21.5 9.47935 21.2103 10.1788 20.6945 10.6945C20.1788 11.2103 19.4793 11.5 18.75 11.5H5.25C4.52065 11.5 3.82118 11.2103 3.30546 10.6945C2.78973 10.1788 2.5 9.47935 2.5 8.75V5.75C2.5 5.6837 2.52634 5.62011 2.57322 5.57322C2.62011 5.52634 2.6837 5.5 2.75 5.5H21.25C21.3163 5.5 21.3799 5.52634 21.4268 5.57322C21.4737 5.62011 21.5 5.6837 21.5 5.75V8.75ZM15 1.75V4H9V1.75C9 1.6837 9.02634 1.62011 9.07322 1.57322C9.12011 1.52634 9.1837 1.5 9.25 1.5H14.75C14.8163 1.5 14.8799 1.52634 14.9268 1.57322C14.9737 1.62011 15 1.6837 15 1.75Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div class="header__login--info">
                  <div
                    class="header__login--name"
                    v-if="
                      GET_USER_DATA.role.name != 'MAIN' ||
                      GET_USER_DATA.role.name != 'EMPLOYER'
                    "
                  >
                    {{ GET_USER_DATA.common_name + " " + GET_USER_DATA.last_name }}
                  </div>

                  <div class="header__login--position">
                    {{ GET_USER_DATA.role.name_ru }}
                  </div>
                </div>
                <div class="header__login--arrow" @click="showDropdown = !showDropdown">
                  <i
                    class="mdi mdi-chevron-up"
                    style="font-size: 33px"
                    v-if="showDropdown"
                  >
                  </i>
                  <i class="mdi mdi-chevron-down" style="font-size: 33px" v-else> </i>
                </div>
              </div>
              <div class="header__login--absol" v-if="showDropdown">
                <div class="header__login_absol--list" v-if="!GET_USER_DATA.admin">
                  <ul class="menu">
                    <li>
                      <a @click="$router.push('/update')">
                        <img src="../assets/img/setting-icon.svg" alt="" />
                        Личные данные 
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  class="header__login_absol--list"
                  v-if="!GET_USER_DATA.admin && GET_USER_DATA.role.name === 'CEO'" 
                >
                  <ul class="menu">
                    <li>
                      <a @click="$router.push('/users')">
                        <i class="mdi mdi-account-outline"></i> Пользователи
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="header__login_absol--login" v-if="GET_USER_DATA.admin == false" @click="logout()">
                  <ul class="menu">
                    <li>
                      <a id="logout-form">
                        <img src="../assets/img/logout-icon.svg" alt="" /> Выход
                      </a>
                    </li>
                  </ul>
                </div>
								<div class="header__login_absol--login" v-if="GET_USER_DATA.admin == true" @click="logoutAdmin()">
                  <ul class="menu">
                    <li>
                      <a id="logout-form">
                        <img src="../assets/img/logout-icon.svg" alt="" /> Выход
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script src="https://js.pusher.com/7.0.3/pusher.min.js"></script>
<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  components: {},
  name: "Header",
  data() {
    return {
      bellView: false,
      showDropdown: false,
      companyName: "",
      userRole: "",
      user: "",
      notifications: [],
      openNotif: false,
      count: 0,
    };
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;

      if (typeof this.esp__array !== "undefined") {
        // this.sendEcp()
      }
    };
  },

  methods: {
    ...mapActions(["SIGN_OUT_USER", "REDIRECT_FALSE", "SIGN_IN_USER"]),
	
    goTo(link, id) {
      this.readNotif(id);
      if (link) this.$router.push(link);
    },
    logout() {
      this.SIGN_OUT_USER();
      this.$router.push("/sign");
    },
		logoutAdmin() {
      this.SIGN_OUT_USER();
      this.$router.push("/admin/sign");
    },
    readNotif(id) {
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "notifications/read",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          id: id,
        },
      })
        .then((response) => {
          this.count = response.data.length;
          this.notifications = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCount() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "notifications/count",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.count = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getNotifications() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "notifications",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.notifications = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
		
    Pusher.logToConsole = true;
    let pusher = new Pusher("d664bdb583f823af07f8", {
      cluster: "eu",
      encrypted: true,
    });
    let channel = this.GET_USER_DATA.admin
      ? pusher.subscribe("admin-channel." + this.GET_USER_DATA.id)
      : pusher.subscribe("user-channel." + this.GET_USER_DATA.id);
    let typeEvent = this.GET_USER_DATA.admin ? "admin-event" : "user-event";
    let t = this;
    channel.bind(typeEvent, function (data) {
      t.getCount();
      t.getNotifications();
    });
    t.getCount();
    t.getNotifications();
    // if (!localStorage.getItem("access_token")) {
    //   this.SIGN_IN_USER();
    //   this.GET_USER_DATA = [];
    // }
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
